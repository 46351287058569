import React, { useState, useEffect, useRef } from "react";
import { OutboundLink } from "gatsby-plugin-google-gtag";

import Header from "./header.js";
import CharsImage from "./chars-image.js";
import BtnMostrarMais from "./btn-mostrar-mais.js";
import Footer from "../footer.js";

import azhurImg from "../../../static/images/azhur_art_background.png";
import agnisImg from "../../../static/images/agnis_art_background.png";
import hibikiImg from "../../../static/images/hibiki_art_background.png";
import ericaImg from "../../../static/images/erica_art_background.png";
import kirishimaImg from "../../../static/images/kirishima_art_background.png";

import agnisIdleImg from "../../../static/images/agnis_idle.webp";
import ericaIdleImg from "../../../static/images/erica_idle.webp";
import hibikiIdleImg from "../../../static/images/hibiki_idle.webp";
import kirishimaIdleImg from "../../../static/images/kirishima_idle.webp";


function BoralaaLayoutBR (props) {
    const [visibilidadeBotaoApoiar, setVisibilidadeBotaoApoiar] = useState(false);
    const divInicialRef = useRef(null);
    const divApoioRef = useRef(null);
    const resetScrollElement = useRef(null);
    
    useEffect(() => {        
        const observador = new IntersectionObserver((entries) => {setVisibilidadeBotaoApoiar(!entries[0].isIntersecting)}, {threshold: 0.1});
        observador.observe(divInicialRef.current);
        observador.observe(divApoioRef.current);
    }, []);

    const resetarScroll = () => {
        return resetScrollElement.current.scrollTop = 0;
    };

    return (
        <>
            <Header />
            <main>
                <div ref={divInicialRef} className="background-escuro">
                    <div className="container">
                        <h1 className="pb-4 titulo">Boralaa Project</h1>
                        <div className="row">
                            <div className="col-12">
                                <div style={{textAlign: "center"}}>
                                    <p className="subtitulo">A turn-based Boss Rush with Souls-like elements.</p>
                                    <p className="subtitulo">Play alone, with and against your friends or community.</p>
                                </div>
                            </div>

                            <div className="col"></div>
                            <div className="col-12 col-lg-5">
                                <div className="m-4">
                                    <div className="d-flex justify-content-between">
                                        <div className="d-flex flex-column" style={{textAlign: "left"}}>
                                            <span className="numeros-especiais">$ {props.valorTotal}</span>
                                            <small>Total received</small>
                                        </div>

                                        <div className="d-flex flex-column" style={{textAlign: "right"}}>
                                            <span className="numeros-especiais">{props.totalApoiadores}</span>
                                            <small>Backers</small>
                                        </div>
                                    </div>
                                    <div className="d-grid">
                                        <a className="mt-4 btn btn-block btn-danger btn-lg force-link-white" href="#apoiar">Back this Project</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col"></div>
                        </div>

                    </div>
                </div>

                <div className="container background-claro">
                    <h1 className="pb-4 titulo">What is Boralaa?</h1>

                    <div className="m-2 row align-items-center">
                        <div className="mt-2 mb-2 d-flex justify-content-center col-xs-12 col-md-6 order-2 order-sm-1">
                            <CharsImage src={azhurImg} nome={"Azhur, the First"} />
                        </div>
                        <div className="mt-2 mb-2 d-flex justify-content-center col-xs-12 col-md-6 order-1 order-sm-2">
                            <div className="d-flex flex-column">
                                <p>
                                    During a fateful day, a voice was heard by everyone. It didn't matter the place, race or age. 
                                    Everyone in Boralaa listened in sync to the truth that changed the future of that people.
                                </p>
                                <span className="citacao">"Roar, adapt, curse, and bless."</span>
                                <span className="citacao">"Surpass the abomination."</span>
                                <span className="mb-3 citacao">"Pain is Ordeal."</span>
                                <p>
                                    And so a light began to shine. An indication from the heavens informing the place.
                                    The location where everything will happen, with the prophecy set in stone.
                                    A counter displayed the number 365: the time until the first calamity appears.
                                </p>
                                <p>
                                    To unravel the mysteries of Boralaa, you must go through the <strong>Bosses</strong>.
                                    But the hero's life isn't easy, as you have to figure out how to defeat them as the turns go by.
                                </p>
                                <p>
                                    <strong>Zero help, just your attention and strategy!</strong>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="background-escuro">
                    <div className="container">
                        <h1 className="pb-4 titulo">Let's play!</h1>
                        <div className="m-2 row align-items-center">
                            <div className="mt-2 mb-2 d-flex justify-content-center col-xs-12 col-md-6 order-2">
                                <CharsImage src={agnisImg} nome={"Agnis, the Smiling Berserker"} />
                            </div>
                            <div className="mt-2 mb-2 d-flex justify-content-center col-xs-12 col-md-6 order-1">
                                <div className="d-flex flex-column">
                                    <p>
                                        Nothing better than <strong>testing the game</strong> to understand how it works!
                                        In this small <strong>demo</strong>, you'll face the game's initial Boss and get to know more about our idea.
                                    </p>
                                    <p>
                                        You'll notice that the game still <strong>needs some polishing</strong>, especially in terms of visuals and audio effects,
                                        but we'll talk about how we intend to make these adjustments below.
                                    </p>
                                    <p>
                                        And despite the demo only having the single player mode, in the future you will be able to <strong>play with and against</strong> your friends.
                                        Or if you're a streamer, live with and against <strong>your community</strong>.
                                    </p>
                                    <p>
                                        The demo is available on <a href="https://katdevs.itch.io/boralaa" target="_blank" rel="noopener noreferrer">Itch.io </a>
                                        and <a href="https://store.steampowered.com/app/2007460/Boralaa/" target="_blank" rel="noopener noreferrer">Steam</a>,
                                        for <strong>Windows</strong> in <strong>Portuguese</strong> and <strong>English</strong>.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container background-claro">
                    <h1 className="pb-4 titulo">Lore Focused</h1>

                    <div className="m-2 row align-items-center">
                        <div className="mt-2 mb-2 d-flex justify-content-center col-xs-12 col-md-6 order-2 order-sm-1">
                            <CharsImage src={hibikiImg} nome={"Hibiki, the Potion Freak"} />
                        </div>
                        <div className="mt-2 mb-2 d-flex justify-content-center col-xs-12 col-md-6 order-1 order-sm-2">
                            <div className="d-flex flex-column">
                                <p>
                                    Boralaa was created with the idea of <strong>lore first</strong>: the story was created first and the game was developed around it.
                                </p>
                                <p>
                                    We believe that a rich story is a huge source of entertainment, both inside and outside of the game.
                                    And we want to create an <strong>experience as good</strong> as other games have brought to us.
                                </p>
                                <p>
                                    Below you can read to know more about what we have prepared for the <strong>Boralaa universe</strong>!
                                </p>
                            </div>
                        </div>
                    </div>

                    <hr className="m-4"></hr>

                    <div className="m-2 row">
                        <div className="mb-4 col-12 col-sm-4">
                            <div className="list-group" id="list-tab" role="tablist" aria-hidden="true" onClick={resetarScroll}>
                                <div className="d-flex justify-content-between align-items-center list-group-item list-group-item-action active"
                                    style={{height: "80px", cursor: "pointer"}}
                                    id="list-hero-agnis"
                                    data-bs-toggle="list"
                                    href="#list-agnis"
                                    role="tab"
                                    aria-controls="list-agnis">
                                    <span>Agnis</span>
                                    <img src={agnisIdleImg} className="img-fluid" alt="Agnis Pixel Art"></img>
                                </div>
                                <div className="d-flex justify-content-between align-items-center list-group-item list-group-item-action"
                                    style={{height: "80px", cursor: "pointer"}}
                                    id="list-hero-erica"
                                    data-bs-toggle="list"
                                    href="#list-erica"
                                    role="tab"
                                    aria-controls="list-erica">
                                    <span>Erica</span>
                                    <img src={ericaIdleImg} className="img-fluid" alt="Erica Pixel Art"></img>                                    
                                </div>
                                <div className="d-flex justify-content-between align-items-center list-group-item list-group-item-action"
                                    style={{height: "80px", cursor: "pointer"}}
                                    id="list-hero-hibiki"
                                    data-bs-toggle="list"
                                    href="#list-hibiki"
                                    role="tab"
                                    aria-controls="list-hibiki">
                                    <span>Hibiki</span>
                                    <img src={hibikiIdleImg} className="img-fluid" alt="Hibiki Pixel Art"></img>                                    
                                </div>
                                <div className="d-flex justify-content-between align-items-center list-group-item list-group-item-action"
                                    style={{height: "80px", cursor: "pointer"}}
                                    id="list-hero-kirishima"
                                    data-bs-toggle="list"
                                    href="#list-kirishima"
                                    role="tab"
                                    aria-controls="list-kirishima">
                                    <span>Kirishima</span>
                                    <img src={kirishimaIdleImg} className="pe-2 img-fluid" alt="Kirishima Pixel Art"></img>                                  
                                </div>
                            </div>
                        </div>
                        
                        <div ref={resetScrollElement} style={{height: "320px"}} className="col-12 col-sm-8 overflow-auto">
                            <div className="tab-content" id="nav-tabContent">
                                <div className="tab-pane fade show active" id="list-agnis" role="tabpanel" aria-labelledby="list-hero-agnis">
                                    <p>
                                        Daughter of the Sigmond, renowned hunters in Winter Sun, Agnis came from an unlikely union between a methodical, strict father and an outgoing,
                                        optimistic mother who got along surprisingly well. Together with her brother, they lived happily amid the family routine of hunts.
                                    </p>
                                    <p>
                                        One day a government emissary knocked on the door. Seeing his insignia, Agnis soon realized what was to come: a high-level mission.
                                        An unknown beast that was plaguing one of the city's borders and they were requested to their hunt. Unlike the other missions, the two simblins
                                        this time could not participate. The next day, Agnis received a kiss from her mother on the forehead as usual before her parents left for another
                                        day's work. She just didn't imagine that one kiss was the last she would get.
                                    </p>
                                    <p>
                                        Days later, the beast was defeated. The commemoration was absorbed by mourning, in one of the battles with the lowest hunter casualties ever recorded.
                                        Among them, the mother of Agnis. Seriously injured and without his wife, his father's recovery was arduous. Embittered by the events and his own failure,
                                        he fell into a deep depression.
                                    </p>
                                    <p>
                                        Surviving all that unhappy moment, Agnis had only memories as comfort for the pain she was suffering. The only thing that eased all the despair in her
                                        heart was the memory of her mother's beautiful smile. Gradually, he began to imitate her. She wanted everyone to know the same smile that she miss so much.
                                    </p>
                                    <p>
                                        With hard training and strength of will, she went beyond her physical limits in search of becoming a hunter as well. At the National Academy, she was nicknamed
                                        the Smiling Berserker for her aggressive fighting style and a smile on her face, regardless of the situation. During her participation in the Dawn of Winter,
                                        there was great anticipation in the air as the Sigmond daughter would participate. During the event, her skills and smile were recognized,
                                        as well as being chosen for the elite of the family Tiwaz, the same as her parents.
                                    </p>
                                    <p>
                                        As she slew monsters and completed quests, her scars and reputation as a hunter only grew. But to this day, there is a rumor. Rumor that the Smiling Berserker
                                        doesn't have a single scar on her back.
                                    </p>
                                </div>
                                <div className="tab-pane fade" id="list-erica" role="tabpanel" aria-labelledby="list-hero-erica">
                                    <p>
                                        Erica was always a calm child. Being the eldest sister, she was always responsible for her 2 twin siblins while her parents were away. In keeping with tradition,
                                        her parents have sent her to the National Academy at age 10.
                                    </p>
                                    <p>
                                        In the academy, she was very popular for her kindness and altruism, dealing well with people and mediating the fights that occasionally happened.
                                        Even though consistently highly rated in all academy subjects, she was considered a prodigy in handling Astra and controlling the bow and arrow. When she was holding it,
                                        something changed. A small smile crept onto her face as all her calm became focus, ensuring the most impressive hits and earning praise from her instructors.
                                    </p>
                                    <p>
                                        However, it was during the Dawn of Winter that Erica gained her fame. Using complex enchantments to protect and heal her allies, creating arrows of pure Astra
                                        and firing them with incredible precision, she earned the title of Absolute Focus at the end of the event. Erica received the official invitation from all the
                                        matriarchs, but decided for the Gebo family, the same family that her parents are part of.
                                    </p>
                                    <p>
                                        Following the new ideals received, Erica learned the value of balance. Be generous, but relentless in the face of the enemy. As a reward for her achievements
                                        and skills, at age 16 she received her Techno Bow: an enchanted technological bow, capable of receiving Astra charges and enhancing the skills of its user.
                                        She currently leads part of the Winter Sun Defense Forces.
                                    </p>
                                </div>
                                <div className="tab-pane fade" id="list-hibiki" role="tabpanel" aria-labelledby="list-hero-hibiki">
                                    <p>
                                        Born into a family of humble origins, who owned a kiosk on the coast of Utsuichi, Hibiki helped her parents from a very young age. She learned to cook with
                                        her father, but the heat from the stove held another taste: alchemy. All thanks to a book that was forgotten there. While the owner was not found, Hibiki
                                        marveled at the new world that was in her palms. Even using the improvisation and the little resource he had, Hibiki managed to take her first steps alone
                                        within that mysterious science.
                                    </p>
                                    <p>
                                        Days later, in search of the lost book, the alchemist heard about the girl's feats. Impressed, she promised to help her with the studies, bringing knowledge
                                        and resources whenever she passed through the region. But it would be up to Hibiki to work hard and learn.
                                    </p>
                                    <p>
                                        As the years passed, her curiosity and effort resulted in a remarkable evolution. Not just in common alchemy, but with innovative manipulations and surprising
                                        effects. As a form of recognition, her teacher started to recommend some jobs. Due to the high quality of its products, Hibiki started to receive more and more
                                        demand. So she decided to set up a small shop, alongside her parents.
                                    </p>
                                    <p>
                                        In a short time, Beco da Gata gained fame throughout Boralaa, both for its items and the peculiarities of its owner. Because she can't handle her addiction,
                                        spending nights reading and practicing became a habit. So it's common to find Hibiki taking naps in random corners of the store. And besides using only square
                                        vials for her potions, it's normal to see her head over heels in the most unusual places, thinking about solutions to her problems.
                                    </p>
                                </div>
                                <div className="tab-pane fade" id="list-kirishima" role="tabpanel" aria-labelledby="list-hero-kirishima">
                                    <p>
                                        Kirishima was born into the Miyazaki tribe, responsible for the southeastern region of Utsuichi. Due to a mysterious incident that resulted in the loss of his parents,
                                        he was raised from an early age by the Kuwahara Clan, one of the most radical in the region. Shaped by the ideas of violence, chaos and freedom, Kirishima has become
                                        the perfect tool for the perfect plan: to end the Utsuichi Federation and all humans who live in it.
                                    </p>
                                    <p>
                                        Following the orders of his leaders, his path was marked by assaults and executions. Even though he was young, traces of destruction and blood were left wherever he went.
                                        And he did everything with great pleasure, truly believing in the ideals he was taught.
                                    </p>
                                    <p>
                                        On one mission, a group was tasked with breaking into an institution and destroying everything inside. Kirishima was given the job of burning down the place, and so he did.
                                        With his task completed, he went to meet the other members. As he approached, the heat from the flames mingled with crying and the smell of blood. Walking along,
                                        he found several bodies of human and semi-human children, brutally murdered. Confused, he ran for answers.
                                    </p>
                                    <p>
                                        When he found them, they were entertained by a group of young people who were in agony, asking for help. After stopping them, he questioned what was going on and where
                                        they were. "Of course it's a school. Where else could we find so many little shits with human blood at once?" replied one of them. "We have to nip the evil in the bud"
                                        said another. Kirishima didn't understand why, but witnessing such a situation, both the state of those children in suffering and fear, screaming for their lives, as
                                        well as the pleasure of his colleagues, made him burn with hatred! How someone like him could feel that way, being the executioner of so many innocents. Why would seeing
                                        those kids in such a state have shaken him? Questions he didn't have time to answer.
                                    </p>
                                    <p>
                                        Releasing all his rage, like a bear protecting its cubs, he fought off the attackers. No matter what attacks or wounds he took, he took down one after another.
                                        Before the building collapsed, he saved everyone he could. With the arrival of the Steel Claws, the clan responsible for dealing with the actions of the Kuwahara,
                                        the situation was brought under control. Everyone was in disbelief at what had happened, as the infamous Kirishima had saved 52 children alone. And in exchange,
                                        50% of the body was charred. Taken prisoner by the Steel Claws, he survived thanks to the treatment received from the clan's healers. However, the aftereffects remained.
                                        Even standing up alone became a difficult task.
                                    </p>
                                    <p>
                                        During the years he was in custody, he was constantly visited by the young people he saved, with gifts or gratefulness. Iconically, in all of them there was some mention
                                        of the Steel Claws motto: "Honoring the past, protecting the future". Even not knowing how to handle the situation, Kirishima started to feel strangely comfortable with
                                        the occasions. As time passed, he understood better the feeling of that fateful day. Everything he had learned as a absolute reality was nothing but a point of view 
                                        corrupted by hate.
                                    </p>
                                    <p>
                                        Around the age of 27, almost a decade after the event, Kirishima made a decision. To atone for his sins, he would do whatever he could to help the Steel Claws.
                                        He would continue to protect people in the same way he did that day. After a public hearing with a large part of the clan present, his request was accepted. Not only did
                                        it become an official member, but he was included in the REZ program: a partnership with the Republic of Tecnos for physical rehabilitation through technology and magic.
                                    </p>
                                    <p>
                                        That was the first step in Kirishima's big turnaround. Who knew that 15 years later, he would become the successor and Boss of the Steel Claws.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="background-escuro">
                    <h1 className="pb-4 titulo">Our plans</h1>

                    <div className="container">
                        <div className="m-2 row">
                            <div className="col-12">
                                <p>
                                    All production so far has been done with little resources, being financed out of <strong>my own savings</strong>.
                                    Despite being our first game, we gained a lot of experience producing this demo
                                    and now we are <strong>looking for resources</strong> to continue with the project.
                                </p>
                                <p>
                                    In its current state, the game is in need of improvements in: <strong>VFX</strong>, <strong>SFX</strong>,
                                    <strong> voice acting</strong>, <strong>environment</strong>, and the <strong>remaining Bosses and Heroes</strong>.

                                    For this, we created <strong>goals</strong> to help with the project management. As they are reached, we will use the resources
                                    in the game and start the related production.
                                </p>
                                <p>
                                    One of our plans is a <strong>transparent</strong> development. All our decisions and updates will be informed through
                                    our <a href="https://discord.gg/x3qq87QS2P" target="_blank" rel="noopener noreferrer">Discord</a> and <a href="https://twitter.com/KatDevs" target="_blank" rel="noopener noreferrer">Twitter</a>. 
                                    Follow us to stay up to date with Boralaa news, as well as being able to leave your feedback.

                                    
                                </p>
                            </div>
                        </div>

                        <div className="m-2 row row-cols-1 row-cols-md-2 row-cols-lg-3 gx-4 gy-3">

                            <div className="col">
                                <div className="card h-100 text-white bg-danger">
                                    <div className="card-header d-flex justify-content-between">
                                        <span>1° goal</span>
                                        <span>In progress</span>
                                    </div>
                                    <div className="card-body d-flex flex-column justify-content-around">
                                        <h5 className="card-title">
                                            <strong>$ 2.000</strong>
                                        </h5>
                                        <p className="card-text">
                                            The goal is to adjust the details that are missing from the first stage (which is in the demo).
                                        </p>
                                        <span>
                                            We still need to add visual effects to the attacks, audio and improve the quality of the environment.
                                        </span>
                                        <hr />
                                        <div>
                                            <span className="span-card-collapse"><strong>VFX</strong>: $ 400</span>
                                            <span className="span-card-collapse"><strong>SFX and Dub</strong>: $ 400</span>
                                            <span className="span-card-collapse"><strong>Environment</strong>: $ 400</span>
                                            <span className="span-card-collapse"><strong>Labor</strong>: $ 600</span>
                                            <span className="span-card-collapse"><strong>Security Reserve</strong>: $ 200</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col">
                                <div className="card h-100 border-danger bg-transparent text-danger">
                                    <div className="card-header d-flex justify-content-between">
                                        <span>2° goal</span>
                                        <span>Waiting</span>
                                    </div>
                                    <div className="card-body d-flex flex-column justify-content-around">
                                        <h5 className="card-title">
                                            <strong>$ 2.000</strong>
                                        </h5>
                                        <p className="card-text">
                                            Boralaa's story is an essential part of the game's sequence and objective.
                                        </p>
                                        <span>
                                            We want to expand the universe so it can be told both inside and outside of the game.
                                        </span>
                                        <hr />
                                        <div>
                                            <span className="span-card-collapse"><strong>Lore Development</strong>: $ 400</span>
                                            <span className="span-card-collapse"><strong>Concepts and Art</strong>: $ 400</span>
                                            <span className="span-card-collapse"><strong>Digital Collection</strong>: $ 400</span>
                                            <span className="span-card-collapse"><strong>Labor</strong>: $ 600</span>
                                            <span className="span-card-collapse"><strong>Security Reserve</strong>: $ 200</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col">
                                <div className="card h-100 border-danger bg-transparent text-danger">
                                    <div className="card-header d-flex justify-content-between">
                                        <span>3° goal</span>
                                        <span>Waiting</span>
                                    </div>
                                    <div className="card-body d-flex flex-column justify-content-around">
                                        <h5 className="card-title">
                                            <strong>$ 8.000</strong>
                                        </h5>
                                        <p className="card-text">
                                            Development of the second and third stages of the game.
                                        </p>
                                        <span>
                                            The Bosses, Heroes, splash and pixel arts, effects and environment.
                                        </span>
                                        <hr />
                                        <div>
                                            <span className="span-card-collapse"><strong>Pixel Art</strong>: $ 1.200</span>
                                            <span className="span-card-collapse"><strong>Arts</strong>: $ 800</span>
                                            <span className="span-card-collapse"><strong>VFX</strong>: $ 800</span>
                                            <span className="span-card-collapse"><strong>SFX and Dub</strong>: $ 800</span>
                                            <span className="span-card-collapse"><strong>Environment</strong>: $ 800</span>
                                            <span className="span-card-collapse"><strong>Labor</strong>: $ 2.800</span>
                                            <span className="span-card-collapse"><strong>Security Reserve</strong>: $ 800</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div id="metas-linha-2" className="collapse m-2 row row-cols-1 row-cols-md-2 row-cols-lg-3 gx-4 gy-3">
                            <div className="col">
                                <div className="card h-100 border-danger bg-transparent text-danger">
                                    <div className="card-header d-flex justify-content-between">
                                        <span>4° goal</span>
                                        <span>Waiting</span>
                                    </div>
                                    <div className="card-body d-flex flex-column justify-content-around">
                                        <h5 className="card-title">
                                            <strong>$ 8.000</strong>
                                        </h5>
                                        <p className="card-text">
                                            Development of the fourth and fifth stages of the game.
                                        </p>
                                        <span>
                                            The Bosses, Heroes, splash and pixel arts, effects and environment.
                                        </span>
                                        <hr />
                                        <div>
                                            <span className="span-card-collapse"><strong>Pixel Art</strong>: $ 1.200</span>
                                            <span className="span-card-collapse"><strong>Arts</strong>: $ 800</span>
                                            <span className="span-card-collapse"><strong>VFX</strong>: $ 800</span>
                                            <span className="span-card-collapse"><strong>SFX and Dub</strong>: $ 800</span>
                                            <span className="span-card-collapse"><strong>Environment</strong>: $ 800</span>
                                            <span className="span-card-collapse"><strong>Labor</strong>: $ 2.800</span>
                                            <span className="span-card-collapse"><strong>Security Reserve</strong>: $ 800</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col">
                                <div className="card h-100 border-info bg-transparent text-info">
                                    <div className="card-header d-flex justify-content-between">
                                        <span>Extra Goal</span>
                                        <span>Waiting</span>
                                    </div>
                                    <div className="card-body d-flex flex-column justify-content-around">
                                        <h5 className="card-title">
                                            <strong>R$ 5.500</strong>
                                        </h5>
                                        <p className="card-text">
                                            Cinematic / Animation of Boralaa Universe.
                                        </p>
                                        <span>
                                            Although not essential, it will help to promote the game, in addition to be very <strong>COOL</strong>!
                                        </span>
                                        <hr />
                                        <div>
                                            <span className="span-card-collapse"><strong>Studio Contract</strong>: $ 4.000</span>
                                            <span className="span-card-collapse"><strong>Advertising</strong>: $ 1.000</span>
                                            <span className="span-card-collapse"><strong>Security Reserve</strong>: $ 500</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col">
                                <div className="card h-100 border-info bg-transparent text-info">
                                    <div className="card-header d-flex justify-content-between">
                                        <span>Extra Goal</span>
                                        <span>Waiting</span>
                                    </div>
                                    <div className="card-body d-flex flex-column justify-content-around">
                                        <h5 className="card-title">
                                            <strong>$ 12.200</strong>
                                        </h5>
                                        <p className="card-text">
                                            Add animations and effects for each attack for <strong>ALL</strong> Heroes.
                                        </p>
                                        <span>
                                            So far each hero has an animation and effect for all 4 attacks.
                                        </span>
                                        <hr />
                                        <div>
                                            <span className="span-card-collapse"><strong>Pixel Art</strong>: $ 2.400</span>
                                            <span className="span-card-collapse"><strong>VFX</strong>: $ 4.800</span>
                                            <span className="span-card-collapse"><strong>SFX and Dub</strong>: $ 2.000</span>
                                            <span className="span-card-collapse"><strong>Labor</strong>: $ 2.000</span>
                                            <span className="span-card-collapse"><strong>Security Reserve</strong>: $ 1.000</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>

                        <div className="mt-4 d-flex justify-content-center">
                            <BtnMostrarMais id={"#metas-linha-2"} textoPreClique={"Show More"} textoPosClique={"Show Less"}></BtnMostrarMais>
                        </div>

                    </div>
                </div>

                <div className="container background-claro" id="apoiar">
                    <h1 className="pb-4 titulo">Support us!</h1>

                    <div className="m-2 row">
                        <div className="mt-2 mb-2 col-12">
                            <p>
                                Did you like the project and <strong>want to help us</strong> build the game?<strong> LETS GO!</strong> We
                                created some tiers so you can choose how you want to help us.
                                Each one has its reward in thanks for the support, and don't forget:
                                <strong> every help is welcome</strong>, regardless of the amount.
                                Gradually, we will get there!
                            </p>
                            <p>


                                And don't worry about security. We've partnered with <a href="https://stripe.com/" target="_blank" rel="noopener noreferrer">Stripe</a>,
                                payment platform used worldwide by companies like <strong>Google</strong> and <strong>Zoom</strong>.
                                And be sure to provide your <strong>email</strong> during checkout so we can contact you in the future.
                            </p>
                        </div>
                    </div>

                    <div ref={divApoioRef} className="m-2 row row-cols-1 row-cols-md-2 row-cols-lg-3 gx-4 gy-3">

                        <div className="col">
                            <div className="card h-100 text-white bg-danger">
                                <div className="card-body d-flex flex-column justify-content-around">
                                    <h1 className="titulo-no-align">Tier Thx</h1>
                                    <p className="card-text">
                                        A big thank you from the bottom of my heart! It may not seem like much, but everyone helping a bit, we can do it.
                                    </p>
                                    <div className="d-flex flex-column">
                                        <span><strong style={{color: '#022029'}}>Rewards</strong></span>
                                        <span>- A big Thank You! :D</span>
                                        <span>- HD Digital Wallpaper</span>
                                    </div>
                                    <hr></hr>
                                    <div className="d-flex flex-column" style={{textAlign: "center"}}>
                                        <span className="numeros-especiais-fundo-branco">$ 2+</span>
                                        <small>Pledge</small>
                                        <span className="numeros-especiais-fundo-branco">{props.tier1}</span>
                                        <small>Backers</small>
                                        <OutboundLink 
                                            className="mt-4 btn btn-lg btn-block btn-dark force-link-white"
                                            href="https://buy.stripe.com/dR67tO2q8bQza0UcMT"
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            I want this Tier!
                                        </OutboundLink>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col">
                            <div className="card h-100 text-white bg-danger">
                                <div className="card-body d-flex flex-column justify-content-around">
                                    <h1 className="titulo-no-align">Tier Get the Game</h1>
                                    <p className="card-text">
                                        In this one, you'll get a digital copy of the game when ready and released on STEAM.
                                    </p>
                                    <div className="d-flex flex-column">
                                        <span><strong style={{color: '#022029'}}>Rewards</strong></span>
                                        <span>- A big Thank You! :D</span>
                                        <span>- HD Digital Wallpaper</span>
                                        <span>- Digital copy of the Game (STEAM)</span>
                                    </div>
                                    <hr></hr>
                                    <div className="d-flex flex-column" style={{textAlign: "center"}}>
                                        <span className="numeros-especiais-fundo-branco">$ 10</span>
                                        <small>Pledge</small>
                                        <span className="numeros-especiais-fundo-branco">{props.tier2}</span>
                                        <small>Backers</small>
                                        <OutboundLink 
                                            className="mt-4 btn btn-lg btn-block btn-dark force-link-white"
                                            href="https://buy.stripe.com/eVacO83uccUDfle3ck"
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            I want this Tier!
                                        </OutboundLink>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col">
                            <div className="card h-100 text-white bg-danger">
                                <div className="card-body d-flex flex-column justify-content-around">
                                    <h1 className="titulo-no-align">Tier Two for One</h1>
                                    <p className="card-text">
                                        Ideal tier if you want to gift one of your friends with a nice discount.
                                    </p>
                                    <div className="d-flex flex-column">
                                        <span><strong style={{color: '#022029'}}>Rewards</strong></span>
                                        <span>- A big Thank You! :D</span>
                                        <span>- HD Digital Wallpaper</span>
                                        <span>- 2 digital copies of the Game (STEAM)</span>    
                                    </div>
                                    <hr></hr>
                                    <div className="d-flex flex-column" style={{textAlign: "center"}}>
                                        <span className="numeros-especiais-fundo-branco">$ 15</span>
                                        <small>Pledge</small>
                                        <span className="numeros-especiais-fundo-branco">{props.tier3}</span>
                                        <small>Backers</small>
                                        <OutboundLink 
                                            className="mt-4 btn btn-lg btn-block btn-dark force-link-white"
                                            href="https://buy.stripe.com/28oaG05Ck3k3b4YeV3"
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            I want this Tier!
                                        </OutboundLink>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col">
                            <div className="card h-100 text-white bg-danger">
                                <div className="card-body d-flex flex-column justify-content-around">
                                    <h1 className="titulo-no-align">Tier Patreon</h1>
                                    <p className="card-text">
                                        This is the Tier for those who liked the project and want to help more, and still receiving some pampering.
                                    </p>
                                    <div className="d-flex flex-column">
                                        <span><strong style={{color: '#022029'}}>Rewards</strong></span>
                                        <span>- Previous Rewards</span>
                                        <span>- Closed Beta access</span>  
                                        <span>- Special Role on Discord</span>
                                        <span>- Your name in the Credits</span>
                                    </div>
                                    <hr></hr>
                                    <div className="d-flex flex-column" style={{textAlign: "center"}}>
                                        <span className="numeros-especiais-fundo-branco">$ 25+</span>
                                        <small>Pledge</small>
                                        <span className="numeros-especiais-fundo-branco">{props.tier4}</span>
                                        <small>Backers</small>
                                        <OutboundLink 
                                            className="mt-4 btn btn-lg btn-block btn-dark force-link-white"
                                            href="https://buy.stripe.com/7sI3dy9SA8En4GAdR0"
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            I want this Tier!
                                        </OutboundLink>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col">
                            <div className="card h-100 text-white bg-danger">
                                <div className="card-body d-flex flex-column justify-content-around">
                                    <h1 className="titulo-no-align">Tier Boss</h1>
                                    <p className="card-text">
                                        This one is for those who want to actively participate in the project, receiving early access to versions and being able
                                        to give feedback directly through Discord.
                                    </p>
                                    <div className="d-flex flex-column">
                                        <span><strong style={{color: '#022029'}}>Rewards</strong></span>
                                        <span>- Previous Rewards</span>
                                        <span>- Access to Test Versions</span>
                                        <span>- Direct feedback with us</span>
                                    </div>
                                    <hr></hr>
                                    <div className="d-flex flex-column" style={{textAlign: "center"}}>
                                        <span className="numeros-especiais-fundo-branco">$ 100+</span>
                                        <small>Pledge</small>
                                        <span className="numeros-especiais-fundo-branco">{props.tier5}</span>
                                        <small>Backers</small>
                                        <OutboundLink 
                                            className="mt-4 btn btn-lg btn-block btn-dark force-link-white"
                                            href="https://buy.stripe.com/7sI4hC1m42fZ5KE00b"
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            I want this Tier!
                                        </OutboundLink>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col">
                            <div className="card h-100 text-white bg-danger">
                                <div className="card-body d-flex flex-column justify-content-around">
                                    <h1 className="titulo-no-align">Tier Final Boss</h1>
                                    <p className="card-text">
                                        This tier is for those who can invest and want to help the project a lot.
                                        And in return, they will leave their mark on Boralaa.
                                    </p>
                                    <div className="d-flex flex-column">
                                        <span><strong style={{color: '#022029'}}>Rewards</strong></span>
                                        <span>- Previous Rewards</span>
                                        <span>- Name 1 of the heroes under our review. [LIMITED]</span>
                                    </div>
                                    <hr></hr>
                                    <div className="d-flex flex-column" style={{textAlign: "center"}}>
                                        <span className="numeros-especiais-fundo-branco">$ 500+</span>
                                        <small>Pledge</small>
                                        <span className="numeros-especiais-fundo-branco">{props.tier6}</span>
                                        <small>Backers</small>
                                        <OutboundLink 
                                            className="mt-4 btn btn-lg btn-block btn-dark force-link-white"
                                            href="https://buy.stripe.com/8wM4hC7Ks2fZb4Y3co"
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            I want this Tier!
                                        </OutboundLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="background-escuro">
                    <h1 className="pb-4 titulo">FAQ</h1>

                    <div className="container">
                        <div className="m-2 row align-items-center">
                            <div className="mt-2 mb-2 d-flex justify-content-center col-xs-12 col-md-6 order-2">
                                <CharsImage src={ericaImg} nome={"Erica, the Absolute Focus"} />
                            </div>
                            <div className="mt-2 mb-2 d-flex justify-content-center col-xs-12 col-md-6 order-1">
                                <div className="d-flex flex-column">
                                    <h1 className="pb-4 titulo" style={{textAlign: "left"}}>Why not Kickstarter?</h1>
                                    <p>
                                        Kickstarter only accepts projects from a specific list of countries.
                                        Unfortunately Brazil is <strong>not</strong> in it. Sadge
                                    </p>

                                    <h1 className="pb-4 titulo" style={{textAlign: "left"}}>Where did that idea come from?</h1>
                                    <p>
                                        During one of my lives on Twitch, I was testing some ways of interacting with my community and I found a battle
                                        where the viewers had to defeat a Boss alongside me.
                                    </p>
                                    <p>
                                        It was cool and so I thought: <strong>"If it were better produced, it would be more fun!"</strong>.
                                        And so Boralaa was born!
                                    </p>

                                    <h1 className="pb-4 titulo" style={{textAlign: "left"}}>What is Kat Devs?</h1>
                                    <p>
                                        We are a small <strong>startup</strong> focused on games and the eSports market.
                                        Currently, I <a href="https://linktr.ee/katreque" target="_blank" rel="noopener noreferrer">(Katreque)</a> am
                                        the only member working in the games division and Boralaa is our <strong>1st project</strong> as a studio.
                                    </p>
                                    <p>
                                        I end up doing practically everything in the game, except the artistic part that is commissioned.
                                        The famous case of a small studio that doesn't have the money to hire more people.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container background-claro">
                    <div className="m-2 row align-items-center">
                        <div className="mt-2 mb-2 d-flex justify-content-center col-xs-12 col-md-6 order-2 order-sm-1">
                            <CharsImage src={kirishimaImg} nome={"Kirishima, the Steel Claws' Boss"} />
                        </div>
                        <div className="mt-2 mb-2 d-flex justify-content-center col-xs-12 col-md-6 order-1 order-sm-2">
                            <div className="d-flex flex-column">
                                <h1 className="pb-4 titulo" style={{textAlign: "left"}}>Who is Katreque?</h1>
                                <p>
                                    Yo! I am the CEO of Kat Devs, lover of technology and games, and developer for over 5 years.
                                    I'm engaged and saving money <s>or trying</s> to buy a little place to live.
                                </p>
                                <p>
                                    <strong>My dream</strong> is to work with games in Brazil, but it is very difficult to get investment in this area here.
                                    So I end up needing to work on other projects to survive.
                                </p>
                                <p>
                                    The <strong>goal</strong> is to grow as a company to have enough resources to create and finish our own projects.
                                    But right now I need <strong>your help</strong> to get started.
                                </p>

                                <h1 className="pb-4 titulo" style={{textAlign: "left"}}>What are the risks?</h1>
                                <p>
                                    In order to <strong>not</strong> spend years producing the game, we are here looking for resources to hire an amazing team
                                    and be able to finish the project within the next <strong>two years</strong>. However, this deadline is totally dependent on the goals.
                                </p>
                                <p>
                                    If we <strong>don't hit the goals</strong>, the project may take longer to launch or even scale back for its final release.
                                    But don't worry, everything will be <strong>properly announced in advance</strong>, inline with our transparent development ideal.
                                </p>

                                <h1 className="pb-4 titulo" style={{textAlign: "left"}}>Thank you!</h1>
                                <p>
                                    A big <strong>thank you</strong> to you who read this far!
                                    Being able to live through game development is a dream and your help is very welcome!
                                    If you still have any questions or want to talk to me, send me a PM on
                                    <strong><a href="https://twitter.com/katreque" target="_blank" rel="noopener noreferrer"> Twitter</a></strong>.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            {
                visibilidadeBotaoApoiar &&
                <div className="m-4" style={{position: "fixed", bottom: 0, right: 0}}>
                    <a className="btn btn-lg btn-danger" href="#apoiar" style={{boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.5)"}}>Support Us</a>
                </div>
            }
            <Footer backgroundColor="#022029" />
        </>
    );
}

export default BoralaaLayoutBR;