import React, {useState, useEffect} from "react";

import BoralaaLayoutEN from "../../components/boralaa/layoutEN.js";
import SEO from "../../components/SEO.js";

function BoralaaEn (props) {
    const [mainColor, setMainColor] = useState(props.mainColor);
    const [secondaryColor, setSecondaryColor] = useState(props.secondaryColor);

    useEffect(() => {
        setMainColor(props.mainColor);
        setSecondaryColor(props.secondaryColor);
    }, [props.mainColor, props.secondaryColor])

    return (
        <>
            <SEO title="Boralaa" description={`Boralaa is a turn-based Boss Rush with Souls-like elements. You can play alone, with your friends or community.`} />
            <BoralaaLayoutEN
                valorTotal={55}
                totalApoiadores={3}
                tier1={0}
                tier2={1}
                tier3={0}
                tier4={2}
                tier5={0}
                tier6={0}
            />
        </>
    );
}

export default BoralaaEn;